<div class="row">
  <div class="col-9">
    <div class="rightTable-table">
      <table
        aria-label="Lead Table"
        mat-table
        [dataSource]="filter.leadSource"
        multiTemplateDataRows
        matSort
        class="staticHead"
      >
        <ng-container #rating matColumnDef="rating">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="width: 50px"
          >
            RATING
          </th>
          <td style="padding-left: 0px" mat-cell *matCellDef="let element">
            <star-rating
              #stars
              style="display: flex"
              [locked]="
                currentCompanyId != null ||
                (currentCompanyId == null && expandedElement != element)
              "
              (click)="
                currentCompanyId == null ? (expandedElement = element) : null
              "
              [rating]="element.rating"
              (click)="element.rating = stars.rating"
            ></star-rating>
          </td>
        </ng-container>

        <ng-container matColumnDef="agent">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{
              adminDisplay: currentCompanyId != null || !userCanChange
            }"
          >
            <mat-form-field
              *ngIf="currentCompanyId == null || userCanChange"
              appearance="fill"
              style="width: 150px"
            >
              <mat-label>{{ filter.currentAgentDisplay }}</mat-label>
              <mat-select
                [value]="filter.defaultSelect"
                (selectionChange)="
                  filter.currentAgentFilter = $event.value.key;
                  $event.value.key
                    ? (filter.currentAgentDisplay =
                        filter.users[$event.value.key].agent)
                    : (filter.currentAgentDisplay = 'All Agents');
                  filter.applyFilters(
                    filter.range.value.start,
                    filter.range.value.end
                  )
                "
              >
                <mat-option
                  *ngIf="currentCompanyId == null || userCanChange"
                  [value]="filter.defaultSelect"
                  >All Users</mat-option
                >
                <mat-option
                  *ngFor="let user of filter.users | keyvalue"
                  [value]="user"
                >
                  {{ user.value.agent }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
          <td
            mat-cell
            style="width: 150px"
            *matCellDef="let element"
            [ngClass]="{
              adminDisplay: currentCompanyId != null
            }"
          >
            <p>
              {{ element.fromUserFirstName }} {{ element.fromUserLastName }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="toCompany">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="width: 50px"
          >
            To Company
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              company1: element.toCompany == 'AA',
              company2: element.toCompany == 'SWC',
              company3: element.toCompany == 'SDC',
              company4: element.toCompany == 'C&A'
            }"
            style="
              filter: contrast(50%) hue-rotate(360deg) saturate(50%)
                brightness(150%);
              border-style: solid;
              border-color: gray;
              border-width: 1px;
              height: auto;
              user-select: none;
            "
          >
            <p>{{ element.toCompany }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="text-align: center; width: 100px"
          >
            Client
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="overflow-cell">
              {{ element.firstName }} {{ element.lastName }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>
            <span
              class="mat-sort-header-content"
              style="width: auto; text-align: center"
            >
              Address
            </span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              overflow: hidden;
              white-space: nowrap;
              text-align: left;
              width: 100px;
            "
          >
            <p>
              {{ element.addressLine1 }},
              <ng-container *ngIf="element.addressLine2 != null">
                {{ element.addressLine2 }},
              </ng-container>
              {{ element.suburb }},
              {{ element.state }}
              {{ element.postCode }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="crmStatus">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="text-align: center !important"
          >
            <p>CRM Status</p>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="width: 150px !important; white-space: nowrap; margin: 5px"
            class="whiteTextCell"
            [ngClass]="{
              NewButton: element.crmStatus == 'NEW' && element.crmId != null,
              FailedButton: element.crmId == null,
              WonButton: element.crmStatus == 'WON',
              QualifiedButton: element.crmStatus == 'QUALIFIED',
              LostButton: element.crmStatus == 'LOST',
              QualifiedLostButton: element.crmStatus == 'QUALIFIED - LOST'
            }"
            (click)="
              toCRM(element.toCompanyId, element.crmId, element.crmStatus)
            "
          >
            <div>
              <div
                *ngIf="element.crmId != null; then thenBlock; else elseBlock"
              ></div>
              <ng-template #thenBlock>
                <p>
                  {{ element.crmStatus }}
                </p>
              </ng-template>
              <ng-template #elseBlock
                ><mat-icon class="red-icon">close</mat-icon></ng-template
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="commission">
          <th
            mat-header-cell
            style="text-align: center"
            *matHeaderCellDef
            [ngClass]="{
              adminDisplay: currentCompanyId == null && !showCommission
            }"
          >
            <p class="mat-sort-header-content">Commission</p>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              adminDisplay: currentCompanyId == null && !showCommission
            }"
            style="width: 50px"
          >
            <p>${{ element.commission }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="overflow: visible; width: 50px"
          >
            <mat-form-field appearance="fill" style="width: 200px">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input
                [formGroup]="filter.range"
                [rangePicker]="picker"
              >
                <input
                  matStartDate
                  formControlName="start"
                  style="width: 100px; font-size: inherit"
                  placeholder="Start date"
                  [value]="filter.dateToday"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                  (dateInput)="
                    filter.applyFilters(
                      filter.range.value.start,
                      filter.range.value.end
                    )
                  "
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngFor="let range of filter.rangePresets | keyvalue"
                color="primary"
                (click)="
                  filter.range.value.start = filter.dateConverter(range.value)
                "
                (click)="
                  filter.applyFilters(
                    filter.range.value.start,
                    filter.range.value.end
                  )
                "
              >
                <mat-icon>outlined_flag</mat-icon>
                <p style="display: inline">
                  {{ range.key }}
                </p>
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>{{ ConvertUTC(element.createdAt, true) }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th
            mat-header-cell
            *matHeaderCellDef
            aria-label="row actions"
            style="width: 50px"
          >
            <a
              mat-raised-button
              id="create-lead-button"
              color="primary"
              href="/lead"
              style="min-width: fit-content"
            >
              <p>Create Lead</p>
            </a>
          </th>
          <td mat-cell *matCellDef="let expn">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="
                expandedElement = expandedElement === expn ? null : expn;
                $event.stopPropagation()
              "
            >
              <mat-icon *ngIf="expandedElement !== expn"
                >keyboard_arrow_down</mat-icon
              >
              <mat-icon *ngIf="expandedElement === expn"
                >keyboard_arrow_up</mat-icon
              >
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplayWithExpand.length"
          >
            <div
              class="lead-row-detail"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="expansion-container">
                <div class="rating-info-wrapper">
                  <div>
                    <ng-container>
                      <mat-form-field
                        style="margin-top: 0; min-height: fit-content"
                      >
                        <mat-label>
                          <p>Review Notes</p>
                        </mat-label>
                        <textarea
                          cdkTextareaAutosize
                          matInput
                          [disabled]="currentCompanyId != null"
                          [value]="reviewComment"
                          (input)="onValueChange($event, element)"
                          [defaultValue]="element.reviewNotes"
                        ></textarea>
                      </mat-form-field>
                      <button
                        style="vertical-align: top"
                        mat-raised-button
                        *ngIf="currentCompanyId == null"
                        (click)="submitRating(element)"
                        color="primary"
                      >
                        <p>Apply</p>
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div class="client-info-wrapper">
                  <div class="client-info">
                    <mat-icon> phone</mat-icon
                    ><strong>
                      <p>Ph #:</p>
                    </strong>
                    <p>{{ element.phoneNumber }}</p>
                  </div>
                  <div class="client-info">
                    <mat-icon> email</mat-icon
                    ><strong>
                      <p>Email:</p>
                    </strong>
                    <p>{{ element.emailAddress }}</p>
                  </div>
                </div>
                <div class="agent-info-wrapper">
                  <div class="agent-info">
                    <div class="from-agent">
                      <div>
                        <strong>
                          <p>Created By:</p>
                        </strong>
                        <p>
                          {{ element.createdByFirstName }}
                          {{ element.createdByLastName }}
                        </p>
                      </div>
                      <div>
                        <strong>
                          <p>Company:</p>
                        </strong>
                        <p>{{ element.fromCompany }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="publish-crm">
                    <div *ngIf="currentCompanyId == null">
                      <strong id="crm-button">
                        <p>CRM Stage:</p>
                      </strong>
                      <p>{{ element.crmStage }}</p>
                    </div>
                    <div
                      *ngIf="
                        currentCompanyId == null &&
                        (element.crmStatus == 'LOST' ||
                          element.crmStatus == 'QUALIFIED - LOST')
                      "
                    >
                      <strong id="crm-button">
                        <p>LOST Stage:</p>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="agent-note-style">
                <p><strong>Notes:</strong></p>
                <mat-card>
                  <mat-card-content>
                    <p>
                      {{ element.note }}
                    </p>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsToDisplayWithExpand; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplayWithExpand"
          class="lead-row-row"
          [class.expanded-row]="expandedElement === element"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="detail-row"
        ></tr>
      </table>
    </div>
    <div>
      <table
        aria-label="Summary of User Leads"
        mat-table
        [dataSource]="filter.users | keyvalue"
        multiTemplateDataRows
        id="summaryColumns"
      >
        <ng-container matColumnDef="agent">
          <th mat-header-cell *matHeaderCellDef>
            <p>Agents</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              {{ element.value.agent }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="leads">
          <th mat-header-cell *matHeaderCellDef>
            <p>Leads</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              {{ element.value.leads }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="new">
          <th mat-header-cell *matHeaderCellDef>
            <p>New</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              {{ element.value.new }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="qualified">
          <th mat-header-cell *matHeaderCellDef>
            <p>Qualified</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              {{ element.value.qualified }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="won">
          <th mat-header-cell *matHeaderCellDef>
            <p>Won</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              {{ element.value.won }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="commission">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{
              adminDisplay: currentCompanyId == null && !showCommission
            }"
          >
            Commission
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              adminDisplay: currentCompanyId == null && !showCommission
            }"
          >
            <p>${{ element.value.commission }}</p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: summaryColumns"></tr>
      </table>
      <!-- <table
        aria-label="Total Summary"
        mat-table
        [dataSource]="filter.totalSummary | keyvalue"
        *ngIf="currentCompanyId == null || userCanChange"
      >
        <ng-container matColumnDef="agent">
          <th mat-header-cell *matHeaderCellDef>TOTALS</th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <ng-container matColumnDef="leads">
          <th mat-header-cell *matHeaderCellDef>Leads</th>
          <td mat-cell *matCellDef="let element">
            {{ element.value.leads }}
          </td>
        </ng-container>
        <ng-container matColumnDef="new">
          <th mat-header-cell *matHeaderCellDef>New</th>
          <td mat-cell *matCellDef="let element">
            {{ element.value.new }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qualified">
          <th mat-header-cell *matHeaderCellDef>Qualified</th>
          <td mat-cell *matCellDef="let element">
            {{ element.value.qualified }}
          </td>
        </ng-container>
        <ng-container matColumnDef="won">
          <th mat-header-cell *matHeaderCellDef>Won</th>
          <td mat-cell *matCellDef="let element">
            {{ element.value.won }}
          </td>
        </ng-container>
        <ng-container matColumnDef="commission">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{
              adminDisplay: currentCompanyId == null && !showCommission
            }"
          >
            Commission
          </th>
          <td mat-cell *matCellDef="let element" style="display: none"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="summaryTotalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: summaryTotalColumns"></tr>
      </table> -->
    </div>
  </div>
  <div class="col-3">
    <div class="row">
      <!-- GRAPH CARD -->
      <mat-card id="graphCard"> Activities </mat-card>
      <!-- Admin Card -->

      <div id="admin-buttons">
        <!-- Create New Lead Button -->
        <span class="admin-button-container" *ngIf="currentCompanyId == null">
          <mat-card>
            <mat-slide-toggle [(ngModel)]="showCommission"
              >Show Commission</mat-slide-toggle
            >
          </mat-card>
          <br />
          <button mat-raised-button color="warn" (click)="patchCRMStatus()">
            <p>Refresh Leads</p>
          </button>
          <button mat-raised-button color="warn">
            <p>Generate Report</p>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
