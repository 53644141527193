export const environment = {
  production: true,
  baseApiUrl: 'https://groupleads.aushare.au',
  scopeUrl: ['api://eb75f10e-7418-4039-8860-e8d6154836b5/readaccess'],
  tenantId: '16c25a6e-f726-4dcf-87c6-b448d4e74772',
  uiClientId: '42ab29b3-b2cc-42c4-95ad-d31ef08c2703',
  redirectUrl: 'https://groupleads.aushare.au',
  zoho: {
    url: 'https://crm.zoho.com.au',
    leads: '/crm/approvals/tab/Leads/',
    deals: '/crm/approvals/tab/Potentials/',
  },
  pipeDrive: {
    url: 'https://candasurveyors.pipedrive.com',
    leads: '/leads/inbox/',
    deals: '/deal/',
  },
  cnaQuoteApp: {
    url: 'https://portal.candasurveyors.app',
    sales: '/home/sales/',
    leads: '/home/leads/',
    deals: '/home/jobs/',
  },
  SWC: {
    url: 'https://sydneywidecertifiers.pipedrive.com',
    leads: '/leads/inbox/',
    deals: '/deal/',
  },
  GoogleMaps: {
    key: 'AIzaSyBUpUZ7GfhD92DVjj5k39iPSw9sVZgF26A',
  },
};
