<app-nav-menu></app-nav-menu>

<main class="container-fluid p-0">
  <div class="router-wrapper">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>

  <footer *ngIf="loginDisplay">
    <mat-toolbar>
      <div class="footer-text">How did we do?</div>
    </mat-toolbar>
  </footer>
</main>