import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';

interface userSummary {
  agent: string;
  commission: number;
  leads: number;
  new: number;
  qualified: number;
  won: number;
}

@Component({
  selector: 'filter',
  templateUrl: './filter.html',
})
export class Filter implements OnInit {
  leadSource: any;
  users: { [id: number]: userSummary } = {};

  currentAgentFilter: string | null = null;
  currentAgentDisplay: string = 'All Agents';
  defaultSelect = { default: null };
  userCanChange: boolean = false;
  leadsCount: number = 0;
  totalSummary: { [id: string]: userSummary } = {
    TOTALS: {
      agent: 'TOTALS',
      commission: 0,
      leads: 0,
      qualified: 0,
      new: 0,
      won: 0,
    },
  };

  CommissionStructure: { [id: string]: number } = {
    LOST: 0,
    NEW: 0,
    QUALIFIED: 8,
    'QUALIFIED - LOST': 8,
    WON: 33,
  };
  commissionTotal: number = 0;

  dateToday = Date.now();
  one_day = 1000 * 60 * 60 * 24;
  dateFortnight = Date.now() - 14 * this.one_day;
  dateYTD = Date.now() - 365 * this.one_day;
  dateMTD = Date.now() - 30 * this.one_day;
  dateThreeMonth = Date.now() - 30 * (this.one_day * 3);
  dateSixMonth = Date.now() - 30 * (this.one_day * 6);
  startDate = this.dateFortnight;

  rangePresets: { [id: string]: number } = {
    Fortnight: this.dateFortnight,
    'Three Months': this.dateThreeMonth,
    'Six Months': this.dateSixMonth,
    'Month To Date': this.dateMTD,
    'Year To Date': this.dateYTD,
  };

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(this.startDate)),
    end: new FormControl<Date | null>(new Date(this.dateToday)),
  });

  constructor() {}

  ngOnInit() {}

  applyFilters(start?: Date | null, end?: Date | null) {
    this.dateFilter(start, end);
    this.users = {};
    this.summaryFilter(this.leadSource.filteredData);
  }

  dateConverter(date: number) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(new Date(date)),
      end: new FormControl<Date | null>(new Date(this.dateToday)),
    });
    return new Date(date);
  }

  dateFilter(start?: Date | null, end?: Date | null) {
    end = end ?? new Date(Date.now());
    this.leadSource.filter = [start, end, this.currentAgentFilter];
    this.getSummaryTotals();
  }

  summaryFilter(leads: any) {
    leads.forEach((lead: any) => {
      var agentName = lead.fromUserFirstName + ' ' + lead.fromUserLastName;
      if (!this.users[lead.fromUserId])
        this.users[lead.fromUserId] = {
          agent: agentName,
          commission: 0,
          leads: 0,
          qualified: 0,
          new: 0,
          won: 0,
        };
      lead['commission'] = 0;
      this.calcCommission(lead, this.users[lead.fromUserId]);
    });
  }

  getSummaryTotals() {
    this.totalSummary['TOTALS'] = {
      agent: 'TOTALS',
      commission: 0,
      leads: 0,
      qualified: 0,
      new: 0,
      won: 0,
    };
    this.leadSource.filteredData.forEach((lead: any) => {
      this.calcCommission(lead, this.totalSummary['TOTALS']);
    });
  }

  calcCommission(lead: any, user: any) {
    if (lead.crmStatus != null) {
      lead.commission = this.CommissionStructure[lead.crmStatus];
      if (lead.crmStatus.includes('QUALIFIED')) user.qualified += 1;
      else if (lead.crmStatus.includes('WON')) user.won += 1;
      else if (lead.crmStatus.includes('NEW')) user.new += 1;
    }
    user.leads += 1;
    user.commission += lead.commission;
  }
}
