<header class="navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar shadow mb-4">
  <!-- <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">Group.Leads</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/counter']">Counter</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/fetch-data']">Fetch data</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

  <mat-toolbar class="navMenu" color="{{ modeColor }}">
    <h1><a *ngIf="devMode" class="title" href="/">DEVELOPMENT MODE</a></h1>
    <h1>
      <a *ngIf="!devMode" class="title" href="/">{{ title }}</a>
    </h1>

    <!-- <a mat-button *ngIf="loginDisplay" [routerLink]="['/leads/add']">Add Leads</a> -->
    <h2><a class="menu-item" href="/">HOME</a></h2>

    <div class="toolbar-spacer"></div>
    <span style="padding-right: 20px; user-select: none">{{ userEmail }}</span>
    <!-- <a mat-button [routerLink]="['/']">Home</a>
    <a mat-button [routerLink]="['/counter']">Counter</a>
    <a mat-button [routerLink]="['/fetch-data']">Fetch data</a> -->

    <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">
      Login
    </button>
    <button mat-raised-button color="basic" *ngIf="loginDisplay" (click)="logout()">
      Logout
    </button>
  </mat-toolbar>
</header>